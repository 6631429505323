import React, { lazy, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useScrollTop from 'hooks/useScrollTop';
import PageLoader from 'components/atoms/pageLoader/PageLoader';
import Layout from 'pages/layout/Layout';

const Home = lazy(() => import('pages/home/Home'));
const ContactUs = lazy(() => import('pages/contactUs/ContactUs'));
const HTMLRenderer = lazy(() => import('pages/HTMLRenderer/HTMLRenderer'));

const BaseRoutes = () => {
  useScrollTop();
  const { i18n } = useTranslation();
  document.body.dir = i18n.dir();

  return (
    <Suspense fallback={<PageLoader title="loadingAssets" />}>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<ContactUs />} />
          {/** English Routes */}
          <Route path="/en/privacy" element={<HTMLRenderer type="privacy" />} />
          <Route path="/en/terms&conditions" element={<HTMLRenderer type="serviceTerms" />} />
          <Route path="/en/competitionterms" element={<HTMLRenderer type="competitionTerms" />} />

          {/** Arabic Routes */}
          <Route path="/ar/privacy" element={<HTMLRenderer type="privacy" isArabic />} />
          <Route path="/ar/terms&conditions" element={<HTMLRenderer type="serviceTerms" isArabic />} />
          <Route path="/ar/competitionterms" element={<HTMLRenderer type="competitionTerms" isArabic />} />

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Layout>
    </Suspense>
  );
};
export default BaseRoutes;
