import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import classnames from 'classnames';
import Logo from 'assets/images/logo.png';
import NavButton from 'components/atoms/navButton/NavButton';
import LanguageSwitcher, { languageOptions } from 'components/molecules/languageSwitcher/LanguageSwitcher';
import { getCurrentLanguage } from 'utils/i18n';
import Footer from 'components/molecules/footer/Footer';
import Sidebar from 'components/molecules/sidebar/Sidebar';

import useNavlinks from 'hooks/useNavlinks';
import styles from './Layout.module.scss';

const Layout = ({ children }) => {
  const { i18n } = useTranslation();
  const navLinks = useNavlinks();

  const [currentLanguage, setCurrentLanguage] = useState(languageOptions[0]);

  const onChangeLanguageDropdown = ({ value }) => {
    i18n.changeLanguage(value);
    setCurrentLanguage(languageOptions.find((el) => el.value === value));
  };

  const onChangeLanguageSidebar = () => {
    const lang = i18n.language === 'ar' ? 'en' : 'ar';
    i18n.changeLanguage(lang);
    setCurrentLanguage(languageOptions.find((el) => el.value === lang));
  };

  useEffect(() => {
    const foundLanguage = languageOptions.find((el) => el.value === getCurrentLanguage());
    setCurrentLanguage(foundLanguage);
  }, []);

  return (
    <div className={styles.background}>
      <Row className={styles.header}>
        <Col xs={4} className={styles.logoContainer}>
          <Image className={classnames(styles.logo, 'no-rotate')} src={Logo} />
        </Col>
        <Col xs={4} className={classnames(styles.linkContainer, 'd-none d-md-block h-100')}>
          <Row className="align-items-center mt-1 w-100">
            {navLinks.map(({ label, path, exitSite }) =>
              exitSite ? (
                <Col key={label} className="d-flex align-items-center justify-content-center">
                  <a href={path} target="_self" className={styles.link} rel="noreferrer">
                    {label}
                  </a>
                </Col>
              ) : (
                <NavButton key={label} label={label} to={path} />
              ),
            )}
            <Col className={styles.languageSwitcher}>
              <LanguageSwitcher currentLanguage={currentLanguage} onChange={onChangeLanguageDropdown} />
            </Col>
          </Row>
        </Col>
        <Sidebar currentLanguage={currentLanguage} onChange={onChangeLanguageSidebar} />
      </Row>
      {children}
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
