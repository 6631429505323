/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';

export default () => ({
  // Styling for the wrapper of the select
  control: (base) => ({
    ...base,
    backgroundColor: 'transparent',
    color: 'var(--white)',
    marginBottom: 8,
    border: 'none',
    boxShadow: 'none',
  }),
  singleValue: (base) => ({
    ...base,
    color: 'var(--white)',
    fontSize: 18,
    fontFamily: '"Tajawal-Regular", sans-serif !important',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 80,
  }),

  // Styling for the inner menu of the select
  menu: (base) => ({
    ...base,
    borderTop: '3px solid var(--active-link)',
    backgroundColor: 'var(--black)',
    borderRadius: 'none',
    marginTop: 15,
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
  }),

  // Styling for the indicators
  indicatorSeparator: () => ({}), // Empty will disable it in the component
  dropdownIndicator: (base) => ({
    ...base,
    marginTop: -5,
    color: 'var(--white)',
  }),

  // Styling for the options
  option: (base) => ({
    ...base,
    backgroundColor: 'var(--black)',
    color: 'var(--white)',
    '&:hover': {
      backgroundColor: 'var(--active-link)',
    },
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 18,
    fontFamily: '"Tajawal-Regular", sans-serif !important',
    paddingTop: 15,
  }),
});

const iconStyle = {
  display: 'inline-block',
  width: 25,
  height: 25,
  marginInlineEnd: 10,
};

export const Option = ({ data, ...props }) => (
  <components.Option {...props}>
    <div className="no-rotate" style={{ marginBottom: 5, ...iconStyle }}>
      {data.icon}
    </div>
    <div>{data.label}</div>
  </components.Option>
);

export const SingleValue = ({ data, ...props }) => (
  <components.SingleValue {...props}>
    <div className="no-rotate" style={iconStyle}>
      {data.icon}
    </div>
    <div>{data.label}</div>
  </components.SingleValue>
);

Option.propTypes = {
  data: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    icon: PropTypes.node,
  }).isRequired,
};
SingleValue.propTypes = {
  data: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    icon: PropTypes.node,
  }).isRequired,
};
