import React from 'react';
import PropTypes from 'prop-types';

const MenuIcon = ({ onClick }) => {
  return (
    <div onClick={onClick} role="button" tabIndex={0} onKeyDown={() => {}}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="SVGRepo_bgCarrier" strokeWidth="0" />
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
        <g id="SVGRepo_iconCarrier">
          <path d="M3 7H21" stroke="#FFF" strokeWidth="1.5" strokeLinecap="round" />
          <path d="M3 12H21" stroke="#FFF" strokeWidth="1.5" strokeLinecap="round" />
          <path d="M3 17H21" stroke="#FFF" strokeWidth="1.5" strokeLinecap="round" />
        </g>
      </svg>
    </div>
  );
};

MenuIcon.propTypes = {
  onClick: PropTypes.func,
};

MenuIcon.defaultProps = {
  onClick: null,
};

export default MenuIcon;
