const local = 'LOCAL';
const dev = 'DEV';
const uat = 'UAT';
const prod = 'PROD';

const config = {
  env: process.env.REACT_APP_ENV,
  environments: {
    local,
    dev,
    uat,
    prod,
  },
  isLocalEnv: process.env.REACT_APP_ENV === local,
  isDevEnv: process.env.REACT_APP_ENV === dev,
  appUrl: process.env.REACT_APP_URL,
  appTimeout: process.env.REACT_APP_TIMEOUT,
  websiteUrl: process.env.REACT_APP_WEBSITE_URL,
  supportEmail: process.env.REACT_APP_SUPPORT_EMAIL,
};

export default config;
