import React, { useLayoutEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { Col, Row } from 'react-bootstrap';
import classnames from 'classnames';
import MenuIcon from 'components/icons/MenuIcon';
import useNavlinks from 'hooks/useNavlinks';
import Close from 'components/icons/Close';
import NavButton from 'components/atoms/navButton/NavButton';

import styles from './Sidebar.module.scss';

const Sidebar = ({ currentLanguage, onChange }) => {
  const navLinks = useNavlinks();
  const sidebarRef = useRef();

  const [sidebar, setSidebar] = useState(false);

  const handleResize = () => {
    if (window.innerWidth > 767) {
      setSidebar(false);
    }
  };

  const closeSidebar = () => {
    setSidebar(false);
    window.removeEventListener('animationend', closeSidebar);
  };

  const onClick = () => {
    sidebarRef?.current.classList.add(styles.exiting);
    window.addEventListener('animationend', closeSidebar);
  };

  useLayoutEffect(() => {
    window.addEventListener('resize', handleResize, false);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return sidebar ? (
    <>
      <Col xs={2} /> {/* allows centering of logo in layout */}
      <Col className={styles.sidebar} ref={sidebarRef}>
        <Row className={styles.closeWrapper}>
          <Col className={styles.closeIcon} onClick={onClick}>
            <Close />
          </Col>
        </Row>

        {navLinks.map(({ label, path }) => (
          <Row key={label} className={styles.navButtons}>
            <NavButton label={label} to={path} onClick={onClick} />
          </Row>
        ))}

        <Row className={styles.languageSwitcher} onClick={onChange}>
          <Col>
            {currentLanguage.icon}
            {currentLanguage.label}
          </Col>
        </Row>
      </Col>
    </>
  ) : (
    <Col xs={2} className={classnames('d-md-none d-block ', styles.menuButton)}>
      <MenuIcon className={styles.icon} onClick={() => setSidebar(true)} />
    </Col>
  );
};

Sidebar.propTypes = {
  currentLanguage: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Sidebar;
