import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const useNavlinks = () => {
  const { t } = useTranslation();

  const navLinks = useMemo(
    () => [
      {
        label: t('home'),
        path: '/',
      },
      // {
      //   exitSite: true,
      //   label: t('playNow'),
      //   path: 'https://eur01.safelinks.protection.outlook.com/?url=https%3A%2F%2Fio.jogo.studio%2Fserve%3Fid%3Da2179363-230b-4813-a1c0-ac17c89e0ca1%3Fpk%3Dfc9de079-8b35-45df-b3c2-deae3b4b9d24%26bg%3D2e3539%26height%3D800%26width%3D900&data=05|01|Antoine.Mitri%40mbc.net|c6a1594cb1274fbc6f9308db24c70081|a2b1d6452205433bb4f498b6b3d66dcf|1|0|638144209375193797|Unknown|TWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D|3000|||&sdata=cPyOrRNnVxnZpGSmiSfl3jCy5ZfnBDugUENLhDsQ1Cc%3D&reserved=0',
      // },
      {
        label: t('contact'),
        path: '/contact',
      },
      {
        exitSite: true,
        label: t('livestream'),
        path: 'https://www.wizzogames.com/streaming/#/login',
      },
    ],
    [t],
  );

  return navLinks;
};

export default useNavlinks;
