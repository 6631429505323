import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import ReactSelect from 'react-select';
import FlagUSA from 'components/icons/FlagUSA';
import FlagKSA from 'components/icons/FlagKSA';
import getStyles, { Option, SingleValue } from './selectStyle';
import styles from './LanguageSwitcher.module.scss';

export const languageOptions = [
  { value: 'en', label: 'English', icon: <FlagUSA /> },
  { value: 'ar', label: 'العربية', icon: <FlagKSA /> },
];

const LanguageSwitcher = ({ currentLanguage, onChange }) => {
  return (
    <Col className={styles.switcherContainer}>
      <ReactSelect
        isSearchable={false}
        value={currentLanguage}
        components={{ Option, SingleValue }}
        options={languageOptions}
        styles={getStyles()}
        onChange={onChange}
      />
    </Col>
  );
};

LanguageSwitcher.propTypes = {
  currentLanguage: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default LanguageSwitcher;
