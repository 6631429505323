import { BrowserRouter as Router } from 'react-router-dom';
import history from 'utils/history';
import Routes from 'routes';
import './App.scss';

function App() {
  return (
    <Router history={history}>
      <Routes />
    </Router>
  );
}

export default App;
