import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import styles from './NavButton.module.scss';

const activeStyle = {
  color: 'var(--active-link)',
};

const NavigationLink = ({ label, to, exact, onClick }) => {
  return (
    <Col className={styles.navWrapper}>
      <NavLink
        className={styles.navButton}
        to={to}
        end={exact}
        onClick={onClick}
        style={({ isActive }) => (isActive ? activeStyle : undefined)}
      >
        {label}
      </NavLink>
    </Col>
  );
};

NavigationLink.defaultProps = {
  exact: false,
  onClick: null,
};

NavigationLink.propTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  onClick: PropTypes.func,
};

export default NavigationLink;
