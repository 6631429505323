import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import Logo from 'assets/images/logo.png';
import FacebookIcon from 'components/icons/FacebookIcon';
import YoutubeIcon from 'components/icons/YoutubeIcon';
import TwitterIcon from 'components/icons/TwitterIcon';
import InstagramIcon from 'components/icons/InstagramIcon';

import styles from './Footer.module.scss';

const socialMediaLinks = [
  {
    icon: <FacebookIcon />,
    link: 'https://www.facebook.com/wizzogames',
  },
  {
    icon: <YoutubeIcon />,
    link: 'https://www.youtube.com/channel/UCO_K7YWMoxlDVERf1ab26gg',
  },
  {
    icon: <TwitterIcon />,
    link: 'https://twitter.com/WizzoGames',
  },
  {
    icon: <InstagramIcon />,
    link: 'https://www.instagram.com/wizzogames/',
  },
];

const Footer = () => {
  const { t, i18n } = useTranslation();

  return (
    <Row className={styles.footer}>
      <Col xs={12} lg={4}>
        <Row className={styles.thanksForVisiting}>{t('thanksForVisiting')}</Row>
        <div className={styles.socialMedia}>
          {socialMediaLinks.map((el, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <a href={el.link} target="_blank" key={el.icon + index} className={styles.icon} rel="noreferrer">
              {el.icon}
            </a>
          ))}
        </div>
      </Col>
      <Col xs={12} lg={4} className={classnames(styles.copyright)}>
        {t('copyright')}
      </Col>
      <Col xs={12} lg={4} className={styles.logoWrapper}>
        <Row>
          <Image src={Logo} className={classnames(styles.logo, 'no-rotate')} />
        </Row>
        <a
          href={i18n.language === 'ar' ? '/ar/competitionterms' : '/en/competitionterms'}
          target="_blank"
          className={styles.link}
          rel="noreferrer"
        >
          {t('termsAndConditions')}
        </a>

        <a
          href={i18n.language === 'ar' ? '/ar/privacy' : '/en/privacy'}
          target="_blank"
          className={styles.link}
          rel="noreferrer"
        >
          {t('privacyPolicy')}
        </a>
        <a
          href={i18n.language === 'ar' ? '/ar/terms&conditions' : '/en/terms&conditions'}
          target="_blank"
          className={styles.link}
          rel="noreferrer"
        >
          {t('serviceTermsAndConditions')}
        </a>
      </Col>
    </Row>
  );
};

export default Footer;
