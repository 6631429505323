import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import logoLoader from 'assets/images/logoLoader.png';
import styles from './PageLoader.module.scss';

const PageLoader = ({ title }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.navigationLoader}>
      <img src={logoLoader} alt="logo" className="no-rotate" />
      <h6 className={styles.text}>{t(title)}</h6>
    </div>
  );
};

PageLoader.defaultProps = {
  title: 'loadingCms',
};

PageLoader.propTypes = {
  title: PropTypes.string,
};

export default React.memo(PageLoader);
