/* eslint-disable react/style-prop-object */
import React from 'react';

const FlagUSA = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 247 130">
      <g>
        <g fill="#bf0a30">
          <path d="M0 0H247V10H0z" />
          <path d="M0 20H247V30H0z" />
          <path d="M0 40H247V50H0z" />
          <path d="M0 60H247V70H0z" />
          <path d="M0 80H247V90H0z" />
          <path d="M0 100H247V110H0z" />
          <path d="M0 120H247V130H0z" />
        </g>
        <g fill="#fff">
          <path d="M0 10H247V20H0z" />
          <path d="M0 30H247V40H0z" />
          <path d="M0 50H247V60H0z" />
          <path d="M0 70H247V80H0z" />
          <path d="M0 90H247V100H0z" />
          <path d="M0 110H247V120H0z" />
        </g>
      </g>
      <path fill="#002868" fillRule="evenodd" strokeWidth="1pt" d="M0 0H98.8V70H0z" />
      <g fill="#fff" fillRule="evenodd">
        <g>
          <g>
            <path
              d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
              transform="translate(8.233 7)"
            />
            <path
              d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
              transform="translate(24.7 7)"
            />
            <path
              d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
              transform="translate(41.167 7)"
            />
            <path
              d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
              transform="translate(57.633 7)"
            />
            <path
              d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
              transform="translate(74.1 7)"
            />
            <path
              d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
              transform="translate(90.567 7)"
            />
          </g>
          <g>
            <path
              d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
              transform="translate(16.467 14)"
            />
            <path
              d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
              transform="translate(32.933 14)"
            />
            <path
              d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
              transform="translate(49.4 14)"
            />
            <path
              d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
              transform="translate(65.867 14)"
            />
            <path
              d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
              transform="translate(82.333 14)"
            />
          </g>
        </g>
        <g transform="translate(0 14)">
          <g>
            <path
              d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
              transform="translate(8.233 7)"
            />
            <path
              d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
              transform="translate(24.7 7)"
            />
            <path
              d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
              transform="translate(41.167 7)"
            />
            <path
              d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
              transform="translate(57.633 7)"
            />
            <path
              d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
              transform="translate(74.1 7)"
            />
            <path
              d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
              transform="translate(90.567 7)"
            />
          </g>
          <g>
            <path
              d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
              transform="translate(16.467 14)"
            />
            <path
              d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
              transform="translate(32.933 14)"
            />
            <path
              d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
              transform="translate(49.4 14)"
            />
            <path
              d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
              transform="translate(65.867 14)"
            />
            <path
              d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
              transform="translate(82.333 14)"
            />
          </g>
        </g>
        <g transform="translate(0 28)">
          <g>
            <path
              d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
              transform="translate(8.233 7)"
            />
            <path
              d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
              transform="translate(24.7 7)"
            />
            <path
              d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
              transform="translate(41.167 7)"
            />
            <path
              d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
              transform="translate(57.633 7)"
            />
            <path
              d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
              transform="translate(74.1 7)"
            />
            <path
              d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
              transform="translate(90.567 7)"
            />
          </g>
          <g>
            <path
              d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
              transform="translate(16.467 14)"
            />
            <path
              d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
              transform="translate(32.933 14)"
            />
            <path
              d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
              transform="translate(49.4 14)"
            />
            <path
              d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
              transform="translate(65.867 14)"
            />
            <path
              d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
              transform="translate(82.333 14)"
            />
          </g>
        </g>
        <g transform="translate(0 42)">
          <g>
            <path
              d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
              transform="translate(8.233 7)"
            />
            <path
              d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
              transform="translate(24.7 7)"
            />
            <path
              d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
              transform="translate(41.167 7)"
            />
            <path
              d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
              transform="translate(57.633 7)"
            />
            <path
              d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
              transform="translate(74.1 7)"
            />
            <path
              d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
              transform="translate(90.567 7)"
            />
          </g>
          <g>
            <path
              d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
              transform="translate(16.467 14)"
            />
            <path
              d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
              transform="translate(32.933 14)"
            />
            <path
              d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
              transform="translate(49.4 14)"
            />
            <path
              d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
              transform="translate(65.867 14)"
            />
            <path
              d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
              transform="translate(82.333 14)"
            />
          </g>
        </g>
        <g transform="translate(0 56)">
          <path
            d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
            transform="translate(8.233 7)"
          />
          <path
            d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
            transform="translate(24.7 7)"
          />
          <path
            d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
            transform="translate(41.167 7)"
          />
          <path
            d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
            transform="translate(57.633 7)"
          />
          <path
            d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
            transform="translate(74.1 7)"
          />
          <path
            d="M0 -4.004L0.89895 -1.2373 3.808 -1.2373 1.4545 0.47261 2.3535 3.2393 0 1.5294 -2.3535 3.2393 -1.4545 0.47261 -3.808 -1.2373 -0.89895 -1.2373z"
            transform="translate(90.567 7)"
          />
        </g>
      </g>
    </svg>
  );
};

export default FlagUSA;
