import React from 'react';

const FlagKSA = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 744.09 496.06">
      <path fill="#199d00" fillRule="evenodd" d="M0 0H744.09V496.06H0z" />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M187.52 140.62c-.86 11.58-1.89 31.98 7.95 34.07 11.91 1.15 5.35-20.16 9.66-24.02.81-1.91 2.31-1.92 2.44.49v18.07c-.11 5.87 3.75 7.61 6.75 8.82 3.13-.24 5.21-.14 6.44 2.9.48 10.42.97 20.84 1.46 31.26 0 0 7.24 2.07 7.59-17.58.35-11.54-2.31-21.2-.75-23.45.05-2.2 2.87-2.34 4.82-1.26 3.12 2.2 4.5 4.91 9.34 3.82 7.36-2.02 11.79-5.61 11.9-11.26-.43-5.37-1.03-10.74-3.36-16.12.32-.97-1.42-3.5-1.1-4.48 1.32 2.07 3.34 1.9 3.8 0-1.26-4.13-3.2-8.09-6.35-9.8-2.61-2.29-6.42-1.82-7.82 2.96-.64 5.52 1.99 12.07 6.01 17.41.85 2.09 2.05 5.56 1.52 8.68-2.14 1.22-4.27.71-6.06-1.18 0 0-5.86-4.39-5.86-5.37 1.55-9.96.34-11.09-.52-13.85-.6-3.81-2.41-5.04-3.88-7.64-1.46-1.56-3.45-1.56-4.4 0-2.58 4.49-1.38 14.13.49 18.44 1.36 3.97 3.42 6.47 2.45 6.47-.81 2.24-2.47 1.72-3.68-.87-1.72-5.35-2.07-13.34-2.07-16.93-.52-4.46-1.09-13.97-4.02-16.39-1.79-2.44-4.45-1.25-5.37.97-.2 4.43-.22 8.86.28 12.94 2.01 7.15 2.65 13.44 3.62 20.76.27 9.82-5.67 4.26-5.4-.6 1.37-6.32 1.02-16.27-.2-18.79-.97-2.52-2.11-3.14-4.46-2.73-1.86-.11-6.66 5.13-8.01 13.82 0 0-1.15 4.48-1.64 8.45-.66 4.49-3.61 7.65-5.68-.63-1.79-6.03-2.89-20.86-5.89-17.38z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M219.88 188.16c-10.51 5.14-20.68 9.93-31.01 14.9.37-7.03 14.74-19.72 24.54-19.9 6.38.18 4.77 2.47 6.47 5z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M214.46 197.88c-16.34 42.13 38.28 48 44.38 1.72.58-1.89 2.88-3.79 3.28-.69-1.26 41.91-42.25 44.79-49.21 31.6-1.73-3.1-2.25-10.01-2.42-14.16-1.03-8.23-5.35-5.06-6.04 3.11-.69 4.55-.52 5.82-.52 10.19 2.19 33.1 54.97 18.88 63.55-8.46 4.54-15.14-.75-26.3 1.72-26.25 5.24 5.64 12.55.75 14.16-1.21.69-.97 2.42-1.61 3.63-.34 4.08 2.94 11.28 1.55 12.77-3.63.87-5.06 1.56-10.3 1.73-15.71-3.34 1.04-5.81 1.73-6.04 3.11l-.69 4.49c-.29 1.44-3.17 1.49-3.28-.35-1.27-5.76-6.51-6.5-9.67 2.42-2.13 1.73-5.99 2.07-6.39-.52.52-5.98-1.9-6.79-6.74-3.97-1.55-11.86-3.1-23.2-4.66-35.05 2.02-.06 3.86 1.44 5.7-.87-2.02-6.27-6.27-19.11-8.64-20.03-1.15-1.38-2.13-.52-3.62-.17-2.53.81-4.89 2.99-4.15 7.25 3 18.19 4.95 32.06 7.95 50.25.46 2.13-1.33 4.95-3.63 4.66-3.91-2.65-4.89-8-11.57-7.77-4.83.06-10.36 5.3-11.05 10.36-.8 4.03-1.09 8.41 0 11.92 3.4 4.08 7.48 3.68 11.05 2.76 2.94-1.21 5.36-4.14 6.39-3.45.69.86.17 10.53-13.81 17.95-8.46 3.8-15.2 4.67-18.82-2.24-2.25-4.32.17-20.72-5.36-16.92z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M283.79 155.05c3.28-1.21 18.83-18.99 18.83-18.99-.81-.69-1.53-1.21-2.34-1.9-.86-.75-.77-1.5 0-2.25 3.86-2.24 2.62-7.16.61-9.41-3.34-1.49-6.25-1-8.38.09-2.7 2.59-3.33 6.73-1.21 9.32 2.08.98 4.15 3.08 2.77 4.23-6.36 6.8-23.77 18.51-21.76 18.91.43.58 11.14.55 11.48 0z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M189.93 217.99c-5.83 9.29-6.33 23.16-3.12 27.3 1.71 1.95 4.52 2.81 6.6 2.19 3.66-1.58 5.26-9 4.39-11.72-1.22-1.91-2.18-2.21-3.4-.59-2.58 5.24-3.65 1.65-3.87-1.28-.4-5.54.12-10.65.73-14.69.64-4.15-.02-2.88-1.33-1.21z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M439.02 203.12c-5.64-12.13-13.44-24.12-15.92-28.72-2.49-4.61-21.23-31.81-23.99-34.87-6.08-7.23 9.89 3.02-2.02-11.35-4.54-3.89-4.8-4.13-8.57-7.31-1.9-1.35-6.54-3.81-7.36.27-.42 3.6-.19 5.56.41 8.56.47 2 3.38 5.34 4.81 7.28 19.01 25.56 35.87 51.37 52.13 83.82 2.57-1.22 2.01-15.65.51-17.68z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M414.28 243.71c-1.11 1.24 2.74 6.57 7.73 6.56 8.36-.97 15.71-5.66 22.52-18.02 1.82-2.88 5.02-9.04 5.12-13.82.63-28.03-1.41-49.83-5.61-70.09-.26-1.97-.1-4.29.23-4.88.55-.65 2.38 0 3.35-1.59 1.43-1.46-3.79-13.54-6.76-18.19-1.06-2.08-1.43-3.46-3.18.24-1.84 3.02-3.08 8.28-2.93 13.19 3.99 27.59 5.21 51.74 7.81 79.33.22 2.67-.18 6.55-1.95 8.1-6.56 6.85-16.03 15.28-26.33 19.17z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M527.14 243.56c-5.99 3.47-6 7.46-1.15 7.6 8.35-.97 18.23-1.66 25.04-11.95 1.82-2.88 3.98-10.67 4.08-15.45.63-28.02-.37-48.94-4.57-69.2-.27-1.97-1.14-6.51-.81-7.1.55-1.39 3.27.15 4.24-1.45 1.43-1.45-7.05-12.35-10.03-17-1.05-2.08-1.42-3.46-3.17.25-1.84 3.01-2.48 8.42-1.75 13.18 4.44 29.97 7.73 52.48 8.41 79.04-.38 2.52-.47 3.88-1.65 7.05-2.62 3.36-5.52 7.55-8.23 9.57-2.72 2.03-8.51 3.96-10.41 5.46z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M531.64 216.7c-.07-7.01.1-13.06-.13-18.29-.23-5.22-1.16-9.48-2.97-13.19-1.71-3.98-.64-7.17-1.45-11.41-.8-4.24-.6-10.58-1.82-15.61-.33-1.96-1.35-8.25-1.04-8.85.5-1.4 2.38.04 3.31-1.58 1.37-1.51-4.79-17.45-7.91-22-1.13-2.04-3.17-1.35-5.69 1.97-2.33 2.19-1.46 7.17-.57 11.91 5.99 31.28 10.46 59.6 9.49 89.38-.3 2.53 8.84-7.55 8.78-12.33z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M487.34 177.89c-3.77-.07-11.67-7.34-13.97-11.59-.87-2.45-.32-4.83.45-6.23 1.4-.91 3.55-1.93 5.15-.95 0 0 1.67 2.33 1.35 2.63 2.05.99 2.93.42 3.14-.42.14-1.47-.61-2.34-.62-3.95.87-4.38 5.88-5.07 7.76-2.27 1.38 1.7 1.89 5.32 2.1 7.76-.02 1.25-2.04-.21-3.19.09s-1.43 1.62-1.52 2.82c-.2 3.18-.58 8.27-.65 12.11z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M417.75 224.47c1.04-9.52-.38-26.48-.48-32.1-.38-13.27-2.55-38.91-3.58-43.19-1.16-8.08 3.32.88 2.7-3.81-1.45-8.07-5.92-13.53-11.18-20.91-1.69-2.41-1.64-2.9-4.25.58-2.9 6.57-.4 11.09.35 16.21 3.79 16.67 6 32.01 7.03 47.17s1.35 31.56.41 47.53c2.84.11 7.41-4.6 9-11.48z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M544.47 209.22c-6.65-11.16-16.68-23.24-19.36-27.74-2.68-4.49-25.35-33.74-28.24-36.68-8.3-8.71 3.8-1.42-1.59-8.15-4.56-5-5.89-6.57-9.79-9.59-1.96-1.26-3.15-3.68-3.79.44-.26 3.62-.52 7.8-.28 10.85-.01 1.69 1.75 4.87 3.27 6.75 20.11 24.71 42.04 49.93 59.69 81.65 2.52-1.33 1.68-15.57.09-17.53z"
      />
      <path
        fill="#1ba400"
        fillRule="evenodd"
        d="M242.79 188.63c-.48.84-1.54 1.93-1.19 3.05.75 1.02 1.35 1.22 2.59 1.28 1.08 0 2.59.25 2.91-.39.58-.64 1.03-1.94.54-3.18-1.12-2.81-4.26-1.76-4.85-.76z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M467.16 351.26c8.89.32 14.71.4 22.62 1.34 0 0 6.82-.67 9.26-1.04 10.29-.98 10.75 14.7 10.75 14.7-.11 9.2-3.66 9.68-8.2 10.66-2.59.33-3.95-1.55-5.31-3.56-1.71.72-4.03.82-6.84.43-3.71-.23-7.42-.22-11.12-.45-3.94-.34-6.03.41-9.97.06-.81 1.28-1.86 3.04-4.27 2.48-2-.23-4.38-5.85-3.68-10.12 1.45-3.07 1.05-2.08.9-3.43-36.36-.93-73.06-2.55-108.73-2.09-27.9.12-55.46 1.28-83.02 2.44-14.7-.24-25.93-2.55-33.69-13.9.7 0 37.52 2.08 48.28 1.39 19.92-.23 38.1-1.85 58.36-2.43 39.95.69 79.55.69 119.49 3.47-3.82-2.61-3.95-8.79 1.93-10.3.5-.34.76 3.07 1.64 3.01 4.7-.35 2.64 6.02 1.6 7.34z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M306.78 131.07c-6.05 17.3 3.47 36.23 10.07 34.38 4.77 1.98 7.8-7.09 9.75-17.01 1.33-2.79 2.34-3.08 3.03-1.65-.18 13.2.95 16.12 4.34 20.13 7.57 5.84 13.83.74 14.32.25l5.9-5.89c1.31-1.38 3.06-1.46 4.91-.25 1.8 1.64 1.55 4.47 5.4 6.43 3.25 1.3 10.19.3 11.8-2.5 2.16-3.7 2.68-4.98 3.68-6.38 1.54-2.06 4.18-1.14 4.18-.5-.25 1.15-1.8 2.3-.74 4.36 1.83 1.38 2.26.49 3.34.19 3.84-1.84 6.73-10.19 6.73-10.19.17-3.11-1.57-2.86-2.7-2.22-1.48.91-1.57 1.19-3.05 2.1-1.88.27-5.52 1.52-7.32-1.27-1.84-3.35-1.86-8.03-3.27-11.41 0-.25-2.44-5.33-.17-5.66 1.15.22 3.59.86 3.98-1.19 1.2-2.01-2.57-7.69-5.16-10.57-2.24-2.46-5.35-2.76-8.35-.24-2.1 1.93-1.8 4.09-2.21 6.14-.53 2.35-.42 5.24 1.97 8.35 2.09 4.13 5.92 9.46 4.66 16.95 0 0-2.23 3.54-6.13 3.07-1.62-.35-4.25-1.04-5.66-11.42-1.06-7.86.26-18.86-3.08-24.02-1.21-3.11-2.09-6.11-5.02-.79-.79 2.09-4.18 5.26-1.72 11.79 2.01 4.14 2.83 10.87 1.91 18.36-1.39 2.13-1.7 2.85-3.52 4.98-2.57 2.76-5.35 2.05-7.48 1.03-1.99-1.35-3.55-2.04-4.46-6.3.17-6.8.55-17.92-.7-20.28-1.83-3.66-4.85-2.33-6.14-1.23-6.2 5.67-9.26 15.23-11.13 22.84-1.72 5.55-3.55 3.96-4.83 1.72-3.13-2.93-3.35-25.87-7.13-22.1z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M325.01 168.68c2.75-1.94 1.47-3.31 5.58.8 5.14 8.8 8.45 20.2 8.95 30.3-.22 2.48 1.53 4.06 2.34 3.52.47-5.84 14.68-13.98 27.7-15.17 1.99-.43 1.02-4.25 1.35-6.2-.78-7.23 4.06-13.81 10.85-14.34 9.24 1.37 12.32 6.3 12.48 13.83-1 14.46-16.06 16.91-24.52 18.07-1.3.49-1.84 1.09 0 1.79l35.45.16 1.81 1.05c.22.85-.52.14-1.92 2.45-1.4 2.3-3.46 7.62-3.57 11.16-10.56 3.4-21.49 4.89-32.59 6.23-3.86 1.95-5.77 4.55-4.98 7.48 1.3 3.24 9.84 6.49 9.84 6.64 1.63 1.02 3.55 3.39-.46 8.26-17.29-.76-30.69-8.12-35.33-18.51-1.4-1.08-2.91 0-3.87 1.4-6.75 8.7-13.4 16.54-24.91 20.7-6.86 1.72-13.89-1.05-17.21-5.55-2.22-2.55-2.14-5.38-2.95-5.99-3.71 1.64-35.64 15.21-31.6 8.89 7.77-8.32 21.23-14.43 33.11-22.64.86-2.75 2.41-12.05 7.11-15.09.27.03-.75 5.47-.65 7.76.06 1.89-.13 2.63.28 2.14.8-.51 15.22-11.84 16.33-15.31 1.41-1.99.42-7.04.42-7.19-2.7-6.97-6.49-7.56-7.9-11.02-1.27-4.6-.69-9.85 1.94-11.32 2.33-2.12 5.1-1.85 7.65.46 2.91 2.61 5.49 7.71 6.24 11.5-.5 1.51-3.81-.99-4.96-.25 2.04 2.11 2.98 4.53 3.72 7.51 1.88 7.95 1.3 11.04-.59 16.18-6.4 13.46-14.58 17.48-21.73 22.46-.2.07-.32 3.42 2.37 5.23.93.97 4.66 1.47 9.05.07 8.48-4.63 17.29-13.15 21.66-22.64 1.27-7.18-.49-14.8-2.36-21.44-2.81-6.49-6.12-15.76-6.12-15.91-.11-4.05.22-5.45 1.99-7.47z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M232.18 131.25c4.08 1.95 11.76 1.12 11.43-5.46 0-.58-.15-2.55-.2-3.08-.84-1.94-3.11-1.46-3.62.54-.17.66.29 1.72-.31 2.05-.34.35-1.64.15-1.59-1.67 0-.58-.43-1.2-.68-1.57-.26-.17-.43-.22-.89-.22-.57.03-.56.17-.88.65-.13.49-.31.97-.31 1.52-.07.64-.31.87-.79.97-.53 0-.41.06-.84-.22-.26-.28-.58-.38-.58-.86 0-.5-.11-1.3-.26-1.62-.23-.3-.59-.44-1-.54-2.23 0-2.38 2.54-2.25 3.51-.17.18-.27 4.74 2.77 6z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M351.81 181.89c4.08 1.94 13.79.82 11.43-5.46 0-.59-.15-2.55-.21-3.09-.83-1.94-3.1-1.46-3.62.54-.16.66.29 1.73-.3 2.06-.35.34-1.65.14-1.59-1.68 0-.58-.43-1.2-.69-1.57-.26-.17-.42-.21-.89-.21-.57.02-.56.17-.87.65-.13.48-.31.96-.31 1.51-.08.65-.32.88-.8.97-.53 0-.41.06-.84-.21-.26-.28-.58-.39-.58-.87 0-.49-.11-1.29-.26-1.62-.22-.3-.58-.44-.99-.54-2.23.01-2.39 2.55-2.26 3.51-.17.19-.26 4.75 2.78 6.01z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M421.56 160.93c4.09 1.95 11.76 1.12 11.43-5.46 0-.58-.14-2.55-.2-3.09-.83-1.94-3.11-1.46-3.62.55-.17.65.29 1.72-.31 2.05-.34.34-1.64.14-1.59-1.68 0-.57-.43-1.2-.68-1.56-.26-.17-.43-.22-.89-.22-.57.02-.56.17-.87.65-.14.49-.32.96-.32 1.51-.07.65-.31.88-.79.98-.53 0-.41.06-.84-.22-.26-.28-.58-.39-.58-.86 0-.5-.11-1.3-.26-1.63-.23-.29-.59-.43-1-.54-2.23.01-2.38 2.55-2.25 3.52-.17.18-.27 4.74 2.77 6z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M457.69 213.56c-7.11 8.02-3.97 21.28-2.37 24.13 2.35 4.69 4.24 7.7 8.8 10.02 4.16 3.06 7.39 1.15 9.18-.99 4.18-4.34 4.23-15.4 6.19-17.6 1.38-4.02 4.85-3.34 6.53-1.55 1.63 2.35 3.55 3.86 5.95 5.13 3.9 3.44 8.56 4.07 13.15.94 3.13-1.76 5.17-4.04 7.01-8.55 2.04-5.45.9-30.66.49-45.6-.15-1.17-4.05-20.55-4.05-20.76 0-.22-.52-9.89-.95-12.19-.07-.94-.3-1.21.68-1.09 1.04.88 1.18.93 1.83 1.22 1.05.19 1.99-1.59 1.35-3.24-3.24-5.98-6.49-11.97-9.73-17.95-.78-.77-1.79-1.62-3.03.22-1.18 1.03-2.45 2.91-2.41 5.33.29 4.25 1.04 8.58 1.33 12.84 1.3 7.28 2.59 14.56 3.89 21.85 1.23 15.57 1.53 28.32 2.76 43.9-.17 6.59-2.22 12.34-4.14 13.17 0 0-2.93 1.7-4.89-.18-1.43-.57-7.14-9.51-7.14-9.51-2.92-2.68-4.85-1.92-6.92 0-5.73 5.53-8.33 15.88-12.22 23.02-1 1.59-3.84 2.96-6.99-.12-7.98-10.91-3.3-26.43-4.3-22.44z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M423.4 122.72c3.65 1.53 6.23 8.94 5.39 12.56-.73 4.47-2.67 9.3-4.06 8.67-1.52-.56 1.03-4.45-.42-8.52-.81-2.65-5.8-7.5-5.28-8.93-1.03-2.99 2.12-4.3 4.37-3.78z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M469.46 217.96c.77-8.9-.52-14.32-.76-19.55-.22-5.22-5.91-45.11-7.06-49.06-1.39-7.48 5.52-1 4.77-5.35-2.39-5.49-8.35-13.47-10.21-18.23-1.13-2.04-.66-3.86-3.17-.54-2.34 7.63-3.15 13.87-2.26 18.61 6 31.28 12.15 57.3 11.17 87.08 2.84.02 6.12-6.51 7.52-12.96z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M531.93 135.33c3.33 1.65 5.29 10.94 4.92 13.59-.66 4.84-2.43 10.07-3.7 9.39-1.38-.61.27-7.18-.39-9.23-.74-2.87-5.28-8.12-4.81-9.66-.93-3.24 1.94-4.66 3.98-4.09z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M284.22 201.14c3.19 1.22 5.06 8.04 4.71 9.98-.64 3.56-2.33 7.41-3.55 6.91-1.32-.45.27-5.28-.37-6.78-.27-3.65-4.69-5.53-4.6-7.11-.82-2.89 1.85-3.42 3.81-3z"
      />
      <path
        fill="#1b9d00"
        fillRule="evenodd"
        d="M361.25 211.38c4.11.26 6.17 3.48 2.32 4.84-3.81 1.3-7.46 2.32-7.48 7.81 1.41 7.66-1.93 5.03-3.91 3.99-2.35-1.68-8.93-5.73-9.86-14.48-.14-2.09 1.48-3.85 4.1-3.84 3.95 1.08 9.78 1.15 14.83 1.68z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M199.01 120.52c4.7 1.42 4.98 8.33 4.63 10.35-.63 3.69-2.29 7.67-3.49 7.15-1.3-.46-.05-5.47-.67-7.02-.7-2.19-4.68-6.19-4.23-7.37-.88-2.47 1.83-3.55 3.76-3.11z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M291.9 153.12c-3.61 1.95-5.01 7.77-2.76 11.15 2.1 2.99 5.42 1.88 5.86 1.88 3.53.44 5.63-6.63 5.63-6.63s.11-1.98-4.09 1.77c-1.76.33-1.98-.33-2.43-1.33-.37-1.84-.29-3.68.55-5.52.63-1.77-.73-2.54-2.76-1.32z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M318.97 117.84c-1.81 1.22-5.42 4.96-5.53 9.27-.11 2.43-.56 2.42 1.03 3.97 1.15 1.66 2.31 1.51 4.63.29 1.33-.98 1.78-1.63 2.23-3.28.55-2.76-2.92 1.31-3.36-1.76-.77-2.85 1.46-4.02 3.55-6.79.08-1.89.03-3.23-2.55-1.7z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M340.78 121.72c-.78 1.73-1.72 10.75-1.56 10.75-.62 2.69 2.81 3.83 4.38.38 2.34-6.33 2.34-9.02 2.5-11.71-.74-4.09-3.5-3.96-5.32.58z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M478.28 191.69c.47-.47 19.38-13.91 19.38-13.91 1.92-.68 1.51 6.93.62 6.88.37 1.51-18.64 14.42-20 13.9-.94.68-1.87-5.21 0-6.87z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M495.52 191.58c3.34 1.66 4.66 11.4 4.3 14.06.12 5.15-3.22 9.29-4.49 8.61-1.38-.61.13-6.4-.54-8.45-.73-2.87-3.57-8.27-3.09-9.82-.94-3.24 1.78-4.97 3.82-4.4z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M383.06 233.56c1.32-1.92 5.38-4.68 5.47-4.68 1.87-.94 3.71.73 3.59.62.32 1.88-1.18 3.62-.71 6.12.41 1.01.71 2.13 2.55 1.7 3.01-2.36 5.79-2.51 8.79-2.66 2.3.14 2.39 4.03.94 4.06-5.55 1.2-8.03 2.69-11.99 4.2-1.87 1.09-3.48-.3-3.48-.45 0-.16-1.1-1.07-.33-3.55.13-1.99-.67-3.08-2.33-2.86-1.25.68-2.35 1.13-2.98-.31-.24-1.06-.32-1.58.48-2.19z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M515.42 238.81c.81 1.03 1.33 1.99-.07 3.68-1.33 1.22-2.26 1.89-3.59 3.1-.62 1.07-1.02 2.69.89 3.21 3.54.99 11.72-4.31 11.72-4.42 1.32-1 .88-2.88.77-2.88-.77-.88-2.52-.36-3.69-.5-.56 0-2.39-.28-1.51-1.9.72-1 .98-1.62 1.48-2.86.55-1.22.07-2.03-1.92-2.69-2.02-.37-2.83-.19-5.08 0-1.21.26-1.63.8-1.85 2.27.09 2.24 1.45 2.11 2.85 2.99z"
      />
      <path
        fill="#259f00"
        fillRule="evenodd"
        d="M384.69 181.37a3.438 3.125 0 11-6.875 0 3.438 3.125 0 116.875 0z"
        transform="matrix(.86563 .50068 -.30041 .51938 105.27 -102.98)"
      />
      <path
        fill="#259f00"
        fillRule="evenodd"
        d="M384.69 181.37a3.438 3.125 0 11-6.875 0 3.438 3.125 0 116.875 0z"
        transform="matrix(.31678 .69557 -.5691 .13938 278.84 -160.95)"
      />
      <path
        fill="#209000"
        fillRule="evenodd"
        d="M468.2 363.3c9.06.44 17.57.1 26.63.54 1.65 1.4.47 4.82-.62 4.58-2.95-.08-4.64-.15-7.59-.22-.1-2.89-7.47-2.41-7.25.09-3.98.48-7.57-.14-11.55-.29-1.17-1.46-1.02-4.1.38-4.7z"
      />
    </svg>
  );
};

export default FlagKSA;
